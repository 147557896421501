<template lang="pug">
v-card
    v-text-field.mx-2(
        :label='$t("AsignedUsersEditor-vue.search")',
        append-icon='mdi-magnify',
        v-model='search',
        ref='search'
    )
    v-list(dense, min-height='300', max-height='300')
        v-list-item(v-for='(user, i) in getUsers', :key='i')
            v-list-item-action
                v-checkbox(v-model='user.assigned')
            v-list-item-content {{ user.name }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellEditorParams,
    ICellEditorComp,
} from '@ag-grid-community/all-modules';
import { UsersBackend } from '@/api/users/users';

type user = { _id: string; name: string; assigned?: boolean };

@Component<SellerAsignedEditor>({ name: 'SellerAsignedEditor' })
export default class SellerAsignedEditor
    extends Vue
    implements ICellEditorComp
{
    public params!: ICellEditorParams;
    public getGui!: any;
    public users: user[] = [];

    public async created() {
        const allUsers = new UsersBackend().getAllUsers({
            omitClientUsersFromThisSeller: this.params.data._id,
        });
        this.users = (this.params.value || []).map((s: user) => {
            s.assigned = true;
            return s;
        });
        const notAssigned = (await allUsers)
            .filter(
                (as: user) => !this.users.find((s: user) => s._id === as._id),
            )
            .map((as: user) => {
                as.assigned = false;
                return as;
            });
        this.users.push(...notAssigned);
    }

    public refresh(params: ICellEditorParams) {
        this.params = params;
        return true;
    }
    public getValue() {
        return this.users.filter((s) => s.assigned);
    }
    public isPopup() {
        return true;
    }

    public get getUsers() {
        return this.users.filter(
            (a: { _id: string; name: string }) =>
                a.name.indexOf(this.search) >= 0,
        );
    }
    public search = '';
}
</script>

<style lang="scss" scoped>
.v-list {
    overflow-y: auto;
}
</style>


