<template lang="pug">
v-card(min-width='300')
    v-container
        v-text-field(
            v-model='v$.email.$model',
            :label='headerField',
            required,
            :error-messages='v$.email.$errors.map((e) => e.$message)',
            :loading='v$.email.$pending'
        )
    v-card-actions
        v-spacer
        v-btn(color='blue darken-1', text='', @click='cancel') {{ $tc('EmailEditor-vue.cancel') }}
        v-btn(
            color='blue darken-1',
            text='',
            @click='save',
            :disabled='v$.$invalid'
        ) {{ $tc('EmailEditor-vue.save') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellEditorParams,
    ICellEditorComp,
} from '@ag-grid-community/all-modules';
import { required, email, helpers } from '@vuelidate/validators';
import { UsersBackend } from '@/api/users/users';
import throttle from 'lodash/throttle';
import useVuelidate from '@vuelidate/core';
@Component<EmailEditor>({
    name: 'EmailEditor',
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage(
                    this.$t('EmailEditor-vue.errors.email.required').toString(),
                    required,
                ),
                email: helpers.withMessage(
                    this.$t('EmailEditor-vue.errors.email.no-valid').toString(),
                    email,
                ),
                isUnique: helpers.withMessage(
                    this.$t('EmailEditor-vue.errors.email.already-exists').toString(),
                    helpers.withAsync(async (value: string) => {
                        let isValid = true;
                        if (value && value.length && this.v$.email.email) {
                            await new UsersBackend()
                                .checkEmail(this.email)
                                .then((res) => {
                                    isValid = res;
                                })
                                .catch(() => {
                                    return false;
                                });
                        }
                        return isValid;
                    }),
                ),
            },
        };
    },
})
export default class EmailEditor extends Vue implements ICellEditorComp {
    public async testEmail() {
        let isValid = true;

        const t = throttle(() => {
            if (this.email && this.email.length && this.v$.email.email) {
                new UsersBackend()
                    .checkEmail(this.email)
                    .then((res) => {
                        isValid = res;
                    })
                    .catch(() => {
                        return false;
                    });
            }
        }, 2000);
        t();
        return isValid;
    }
    public params!: ICellEditorParams;
    public getGui!: any;
    public initEmail = '';
    public email = '';
    public headerField!: string | undefined;

    public created() {
        this.headerField = this.params.colDef.headerName;
        this.initEmail = this.params.value;
        this.email = this.params.value;
    }
    public getValue() {
        return this.initEmail;
    }
    public isPopup() {
        return true;
    }
    public cancel() {
        this.email = this.initEmail;
        this.params.stopEditing();
    }
    public async save() {
        this.v$.$touch();
        if (!this.v$.$invalid) {
            this.initEmail = this.email;
            this.params.stopEditing();
        }
    }
}
</script>

<style lang="scss" scoped></style>


