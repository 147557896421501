<template lang="pug">
.d-flex
    v-dialog(
        v-if='!removing',
        v-model='dialog',
        persistent,
        max-width='600px',
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on }')
            v-btn(color='error', dark, v-on='on', icon, small)
                v-icon mdi-delete
        v-card(:loading='loading')
            v-card-title
                span.headline {{ $tc('RemoveSeller-vue.title') }}
            v-card-actions
                v-spacer
                v-btn(
                    :disabled='loading',
                    color='blue darken-1',
                    text,
                    @click='dialog = false'
                ) {{ $tc('RemoveSeller-vue.no') }}
                v-btn(
                    :disabled='loading',
                    color='blue darken-1',
                    text,
                    @click='removeSeller'
                ) {{ $tc('RemoveSeller-vue.yes') }}
    v-tooltip(v-else, top)
        template(v-slot:activator='{ on, attrs }')
            v-progress-circular.ma-auto.pl-7(
                indeterminate,
                color='primary',
                v-on='on',
                :size='15',
                :width='2'
            )
        span {{ $tc('RemoveSeller-vue.removing') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';

@Component<RemoveSeller>({
    name: 'RemoveSeller',
})
export default class RemoveSeller extends Vue implements ICellRendererComp {
    public getGui!: any;
    public dialog = false;
    public params!: ICellRendererParams & { delete: (_id: string) => void };
    public loading = false;
    public removing = false;

    public created() {
        this.removing =
            typeof this.params != undefined &&
            this.params.data &&
            this.params.data.removing !== 'undefined'
                ? !!this.params.data.removing
                : false;
    }

    public async removeSeller() {
        this.loading = true;
        await this.params.delete(this.params.value);
        this.loading = false;
        this.dialog = false;
    }

    public refresh(
        params: ICellRendererParams & { delete: (_id: string) => void },
    ) {
        this.params = params;
        return true;
    }
}
</script>

<style lang="scss" scoped></style>


