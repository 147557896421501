<template lang="pug">
v-btn(
    elevation='2',
    small,
    color='primary',
    :href='params.data?.status === "paid" ? params.value : params.data?.hosted_invoice_url',
    target='_blank'
) 
    | {{ params.data?.status === 'paid' ? $t('DownloadButtonRenderer-vue.download') : $t('DownloadButtonRenderer-vue.pay') }}
    v-icon(right, dark) mdi-download
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';

@Component<DownloadButtonRenderer>({ name: 'DownloadButtonRenderer' })
export default class DownloadButtonRenderer
    extends Vue
    implements ICellRendererComp
{
    public getGui!: any;
    public params!: ICellRendererParams;
    public refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
}
</script>

