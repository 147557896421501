<template lang="pug">
.d-flex
    span(v-if='params.value') {{ params.value }}
    v-tooltip(
        v-else-if='params.data && params.data.hasAdvCredentialsToCheck',
        top
    )
        template(v-slot:activator='{ on, attrs }')
            v-progress-circular.ma-auto(
                indeterminate,
                color='primary',
                v-on='on'
            )
        span {{ $tc('SellerAdvertisingCredentialsRenderer-vue.refreshing') }}
    v-btn(
        v-else-if='params.data',
        color='primary',
        x-small,
        @click='amzApiCredentialPopUp()'
    ) {{ $tc('SellerAdvertisingCredentialsRenderer-vue.credentials') }}
    span(v-else) -
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import { AmazonBackend } from '@/api/amazon/amazon';
@Component<SellerAdvertisingCredentialsRenderer>({
    name: 'SellerAdvertisingCredentialsRenderer',
})
export default class SellerAdvertisingCredentialsRenderer
    extends Vue
    implements ICellRendererComp
{
    public getGui!: any;
    public params!: ICellRendererParams & {
        checkAdvertisingCredential: (params: {
            sellerID: string;
            name: string;
        }) => Promise<void>;
        reloadTable: () => void;
        getAdvProfiles: (params: {
            sellerID: string;
            name: string;
            countryForVendor: string;
            advertisingCredentialToSeekProfile?: string;
        }) => void;
    };
    public isOpen = false;
    public connection: any;
    public advAmazonUrl = '';

    public async created() {
        if (this.params && this.params.data) {
            await this.getAmazonLoginUrls();
        }
    }

    public refresh(
        params: ICellRendererParams & {
            checkAdvertisingCredential: (params: {
                sellerID: string;
                name: string;
            }) => Promise<void>;
            reloadTable: () => void;
            getAdvProfiles: (params: {
                sellerID: string;
                name: string;
                countryForVendor: string;
                advertisingCredentialToSeekProfile?: string;
            }) => void;
        },
    ) {
        this.params = params;
        return true;
    }

    public amzApiCredentialPopUp() {
        this.connection = window.open(
            this.advAmazonUrl,
            'Authentication with Amazon',
            'width=600, height=650,status=0, toolbar=0, location=no',
        );

        const timer = setInterval(() => {
            if (this.connection.closed) {
                clearInterval(timer);
                this.endingCredentialsProcess();
            }
        }, 1000);
    }

    public endingCredentialsProcess() {
        if (this.params && this.params.data) {
            const sellerID = this.params.data._id;
            const name = this.params.data.name;
            const isVendor = this.params.data.isVendor;
            const countryForVendor = this.params.data.countries[0];
            if (sellerID && this.params.data) {
                if (isVendor) {
                    this.params.getAdvProfiles({
                        sellerID,
                        name,
                        countryForVendor,
                    });
                } else {
                    if (
                        this.params.data.hasAdvCredentialsToCheck &&
                        this.params.checkAdvertisingCredential
                    ) {
                        this.params.reloadTable();
                        this.params.checkAdvertisingCredential({
                            sellerID,
                            name,
                        });
                    }
                }
            }
        }
    }

    public async getAmazonLoginUrls() {
        this.advAmazonUrl = await new AmazonBackend().getAdvertisingLoginUrls({
            sellerID: this.params.data._id,
            region: this.params.data.region,
        });
    }
}
</script>

<style lang="scss" scoped></style>


