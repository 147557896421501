<template lang="pug">
v-dialog(
    v-model='vendorPopupisOpen',
    transition='dialog-bottom-transition',
    max-width='800'
)
    v-card
        v-card-text
            .ml-3.mr-3.pt-8.pb-4
                p.display-2.text-md-center 🎉
                p.title.text-md-center {{ $tc('VendorIntegrationSuccessPopup-vue.seller-integration-success.title') }}
                p.subtitle-1.mt50 {{ $tc('VendorIntegrationSuccessPopup-vue.seller-integration-success.wait') }}
                p.subtitle-1 {{ $tc('VendorIntegrationSuccessPopup-vue.seller-integration-success.tutorial') }}
                .d-flex.justify-center.mt-4
                    v-btn(
                        link,
                        size='is-medium',
                        href='https://www.youtube.com/watch?v=TJFGH2QILEQ&list=PLd1bgJhFRux1s7FyOfqGE3UGtqGkshN9T',
                        target='_blank'
                    ) {{ $tc('VendorIntegrationSuccessPopup-vue.seller-integration-success.go-to-playlist') }}
        v-card-actions.justify-center
            v-btn.mb-3(type='is-success', @click='vendorPopupisOpen = false') {{ $tc('close') }}
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, PropSync } from 'vue-property-decorator';

@Component<VendorIntegrationSuccessPopup>({
    components: {},
    name: 'VendorIntegrationSuccessPopup',
})
export default class VendorIntegrationSuccessPopup extends Vue {
    @PropSync('open') vendorPopupisOpen!: boolean;
}
</script>


