import { render, staticRenderFns } from "./agGrid.vue?vue&type=template&id=57120297&lang=pug&"
import script from "./agGrid.vue?vue&type=script&lang=ts&"
export * from "./agGrid.vue?vue&type=script&lang=ts&"
import style0 from "./agGrid.vue?vue&type=style&index=0&id=57120297&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_webpack@5.89.0__ejs@3.1.9_lodash@4.17.21_pug@3.0.2_vue-te_w5fda7p7ocsrl7tlssdozy65m4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports