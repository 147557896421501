<template lang="pug">
v-card
    v-card-title
        span.headline {{ $t('PasswordChanger-vue.header') }}
    v-card-text
        v-form(ref='passwordchangerform')
            v-row
                v-col(cols='12')
                    v-text-field(
                        v-if='!admin',
                        v-model='v$.currentPassword.$model',
                        type='password',
                        :label='$t("PasswordChanger-vue.passwords.current.placeholder")',
                        required,
                        @focus='currentPasswordMatches = true',
                        counter='30',
                        :error-messages='v$.currentPassword.$errors.map((e) => e.$message)'
                    )
                    v-text-field(
                        v-model='v$.newPassword.$model',
                        type='password',
                        :label='$t("PasswordChanger-vue.passwords.new.placeholder")',
                        required,
                        counter='30',
                        :error-messages='v$.newPassword.$errors.map((e) => e.$message)'
                    )
                    v-text-field(
                        v-model='v$.newPasswordConfirmation.$model',
                        type='password',
                        :label='$t("PasswordChanger-vue.passwords.newconfirm.placeholder")',
                        required,
                        counter='30',
                        :error-messages='v$.newPasswordConfirmation.$errors.map((e) => e.$message)'
                    )
    v-card-actions
        v-spacer
        v-btn(
            v-if='admin',
            color='blue darken-1',
            text='',
            @click='resetComponent()'
        ) {{ $tc('PasswordChanger-vue.cancel') }}
        v-btn(
            color='blue darken-1',
            text='',
            @click='submitPassword',
            :disabled='v$.$invalid'
        ) {{ $t('PasswordChanger-vue.change') }}
    v-snackbar(
        v-model='snackbar',
        :color='!changePasswordError ? "success" : "error"'
    ) 
        | {{ snackbarMessage }}
        v-btn(text, @click='snackbar = false') {{ $t('PasswordChanger-vue.snackbar.close') }}
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Ref, Prop } from 'vue-property-decorator';
import { UsersBackend } from '../../api/users/users';

import useVuelidate from '@vuelidate/core';
import {
    helpers,
    maxLength,
    minLength,
    required,
    sameAs,
} from '@vuelidate/validators';

@Component<PasswordChanger>({
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        const rules = {
            newPassword: {
                maxLength: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.maxlength').toString(),
                    maxLength(30),
                ),
                minLength: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.minlength').toString(),
                    minLength(8),
                ),
                required: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.new.required').toString(),
                    required,
                ),
            },
            newPasswordConfirmation: {
                required: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.newconfirm.required').toString(),
                    required,
                ),
                sameAsPassword: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.newconfirm.notmatch').toString(),
                    sameAs(this.newPassword),
                ),
            },
            currentPassword: {},
        };
        if (!this.admin) {
            rules.currentPassword = {
                maxLength: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.maxlength').toString(),
                    maxLength(30),
                ),
                minLength: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.minlength').toString(),
                    minLength(2),
                ),
                required: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.current.required').toString(),
                    required,
                ),
                isCorrect: helpers.withMessage(
                    this.$t('PasswordChanger-vue.passwords.current.notmatch').toString(),
                    () => this.currentPasswordMatches,
                ),
            };
        }
        return rules;
    },
})
export default class PasswordChanger extends Vue {
    @Ref() passwordchangerform!: Vue &
        Element & { validate(): boolean; reset(): boolean };
    @Prop() userID: string | undefined;
    @Prop() admin: boolean | undefined;
    public currentPassword = '';
    public newPassword = '';
    public newPasswordConfirmation = '';
    public currentPasswordMatches = true;
    public snackbar = false;
    public snackbarMessage = '';
    public changePasswordError = false;

    //Validación current password
    public get currentPasswordErrors() {
        const errors: string[] = [];
        const currentField = this.v$.currentPassword;
        if (!currentField.$dirty) {
            return errors;
        }
        !currentField.maxLength && errors.push(this.$tc('PasswordChanger-vue.passwords.maxlength'));
        !currentField.required &&
            errors.push(this.$tc('PasswordChanger-vue.passwords.current.required'));
        !currentField.isCorrect &&
            errors.push(this.$tc('PasswordChanger-vue.passwords.current.notmatch'));
        !currentField.minLength && errors.push(this.$tc('PasswordChanger-vue.passwords.minlength'));
        return errors;
    }

    async submitPassword() {
        this.v$.$touch();
        if (!this.v$.$invalid) {
            const usersBackend = new UsersBackend();
            await usersBackend
                .changeUserPassword({
                    currentPassword: !this.admin
                        ? this.currentPassword.trim()
                        : undefined,
                    newPassword: this.newPassword.trim(),
                    userId: this.userID,
                    admin: !!this.admin,
                })
                .then((changePasswordRes) => {
                    if (changePasswordRes.saved) {
                        this.changePasswordError = false;
                        this.currentPasswordMatches = true;
                        this.snackbarMessage = this.$tc('PasswordChanger-vue.success');
                        this.snackbar = true;
                        this.passwordchangerform.reset();
                        // hacemos un emit para recargar la tabla
                        this.resetComponent(true);
                    } else if (changePasswordRes.wrongCurrentPassword) {
                        this.currentPasswordMatches = false;
                        this.passwordchangerform.validate(); // Forzamos el formulario a validarse de nuevo
                    } else {
                        this.changePasswordError = true;
                        this.snackbarMessage = this.$tc('PasswordChanger-vue.failed');
                    }
                })
                .catch(() => {
                    this.changePasswordError = true;
                    this.snackbarMessage = this.$tc('PasswordChanger-vue.failed');
                });
        }
    }
    public resetComponent(reloadTable = false) {
        this.currentPassword = '';
        this.newPassword = '';
        this.newPasswordConfirmation = '';
        this.currentPasswordMatches = true;
        this.changePasswordError = false;
        // descomprobamos todo
        this.v$.$reset();
        this.$emit('closeDialog', reloadTable);
    }
}
</script>


