<template lang="pug">
div
    v-snackbar(v-model='snackBar') {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.excel-uploaded') }}
    v-dialog(v-model='showHelpImage', width='1000')
        img(
            src='https://epinium.com/wp-content/uploads/2022/05/Vendor-Excel.jpg'
        )
    v-card
        v-card-title.d-flex
            | {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.header.title') }}
            span.ml-auto(@click='showHelpImage = true')
                v-icon.pointer(large) mdi-help-circle-outline

        //- .card-header
        //-     p.card-header-title {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.instructions.title') }}
        v-card-text
            v-row
                v-col
                    ol
                        li
                            a(:href='getVendorUrl', target='_blank') {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.instructions.vendor-central') }}
                        li {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.instructions.1') }}
                        li {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.instructions.2') }}
                        li {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.instructions.3') }}
                        li {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.instructions.4') }}
            v-row
                v-col(cols='1')
                    v-tooltip(top)
                        template(v-slot:activator='{ on, attrs }')
                            v-btn(
                                href='vendor_templates/Epinium_vendor_products_template_v2.xlsx',
                                download,
                                v-on='on'
                            )
                                v-icon mdi-file-excel
                        span {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.template-tooltip') }}
                v-col(cols='9')
                    v-file-input.pt-0(
                        v-model='v$.file.$model',
                        accept='.xlsm,.xlsx',
                        required,
                        :error-messages='v$.file.$errors.map((e) => e.$message)',
                        :error-count=5,
                        truncate-length='70'
                    )
                        span
                            v-icon(icon='upload')
                            span {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.upload-file') }}
                        span(v-if='file')
                            | {{ file.name }}
                v-col(cols='2')
                    v-btn(
                        type='is-info',
                        @click='uploadFile',
                        :loading='loading'
                    ) {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.process') }}
            v-row
                v-col
                    //- p.mt10.red--text(v-for='(error, key) in fileErrors') {{ error }}
                    .content.mt10(
                        v-if='uploadResult && uploadResult.error === null'
                    )
                        ul
                            li {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.upload-info.total-products') }}: {{ uploadResult.numTotalProducts }}
                            li {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.upload-info.updated-products') }}: {{ uploadResult.numExistingProducts }}
                            li {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.upload-info.created-products') }}: {{ uploadResult.numNewProducts }}
            v-row
                v-col
                    v-alert(outlined, type='warning', prominent, border='left') {{ $t('UploadVendorProdutsExcel-vue.upload-vendor-products-excel-modal.instructions.excel-warning') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { components } from '@/api/models/Epinium';
import ProductsApi from '@/api/products/products';
import CountryFlag from 'vue-country-flag';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

@Component<UploadVendorProductsExcel>({
    name: 'UploadVendorProductsExcel',
    props: {
        vendor: { required: true },
    },
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            file: {
                required: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.required',
                    ).toString(),
                    required,
                ),
                fileAlredyUploaded: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.file_alredy_uploaded',
                    ).toString(),
                    () => {
                        if (this.file?.uploaded === true) {
                            return false;
                        } else {
                            return true;
                        }
                    },
                ),
                countryIsValid: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.wrong_country',
                    ).toString(),
                    () => {
                        if (this.uploadResult?.error === 'wrong_country') {
                            return false;
                        }
                        return true;
                    },
                ),
                productsIsValid: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.no_products',
                    ).toString(),
                    () => {
                        if (this.uploadResult?.error === 'no_products') {
                            return false;
                        }
                        return true;
                    },
                ),
                queueAddIsValid: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.failed_adding_queue',
                    ).toString(),
                    () => {
                        if (
                            this.uploadResult?.error === 'failed_adding_queue'
                        ) {
                            return false;
                        }
                        return true;
                    },
                ),
                // ERRORES FICHERO COLUMNAS
                hasSkusColumn: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.missing_sku',
                    ).toString(),
                    () => {
                        if (this.uploadResult?.error === 'missing_sku') {
                            return false;
                        }
                        return true;
                    },
                ),
                hasAsinColumn: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.missing_asin',
                    ).toString(),
                    () => {
                        if (this.uploadResult?.error === 'missing_asin') {
                            return false;
                        }
                        return true;
                    },
                ),
                hasVendorCodeColumn: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.missing_vendor_code',
                    ).toString(),
                    () => {
                        if (
                            this.uploadResult?.error === 'missing_vendor_code'
                        ) {
                            return false;
                        }
                        return true;
                    },
                ),
                hasExternalIdColumn: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.missing_external_identifier',
                    ).toString(),
                    () => {
                        if (
                            this.uploadResult?.error ===
                            'missing_external_identifier'
                        ) {
                            return false;
                        }
                        return true;
                    },
                ),
                allExcelColumns: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.fields_advise',
                    ).toString(),
                    () => {
                        if (
                            this.uploadResult?.error ===
                                'missing_external_identifier' ||
                            this.uploadResult?.error === 'missing_asin' ||
                            this.uploadResult?.error ===
                                'missing_vendor_code' ||
                            this.uploadResult?.error === 'missing_sku'
                        ) {
                            return false;
                        }
                        return true;
                    },
                ),
                unkwnownError: helpers.withMessage(
                    this.$t(
                        'upload-vendor-products-excel-modal.form-errors.file.unknown',
                    ).toString(),
                    () => {
                        if (this.uploadResult?.error === 'unknown') {
                            return false;
                        }
                        return true;
                    },
                ),
            },
        };
    },
    components: {
        CountryFlag,
    },
})
export default class UploadVendorProductsExcel extends Vue {
    public vendor!: components['schemas']['SellerTableRespDTO'];
    public file: (File & { uploaded: boolean }) | null = null;
    public showHelpImage = false;
    public loading = false;
    public snackBar = false;
    public uploadResult:
        | components['schemas']['ParseVendorProductsExcelResponseDTO']
        | null = null;
    public deleteDropFile() {
        this.file = null;
    }

    public successSnackBar() {
        this.snackBar = true;
        // this.$buefy.snackbar.open(
        //     `Default, positioned bottom-right with a green 'OK' button`,
        // );
    }

    get getVendorUrl() {
        return (
            this.vendor.countriesObj[0]?.spVendorAuthUrl +
            '/hz/vendor/members/products/mycatalog'
        );
    }

    public async uploadFile() {
        this.uploadResult = null;
        this.v$.$touch();
        if (!this.v$.$invalid) {
            if (this.file) {
                this.loading = true;
                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('sellerID', this.vendor._id as string);
                await ProductsApi.readVendorProductsExcel(formData as any)
                    .then((res) => {
                        if (res) {
                            this.uploadResult = res;

                            if (this.uploadResult && !this.uploadResult.error) {
                                this.successSnackBar();
                            }
                        }
                    })
                    .catch(() => {
                        this.uploadResult = {
                            numTotalProducts: 0,
                            numExistingProducts: 0,
                            numNewProducts: 0,
                            error: 'unknown',
                        };
                    });
                this.file.uploaded = true;
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss">
.pointer:hover {
    cursor: pointer;
}
</style>


