<template lang="pug">
v-container.pa-0(fluid)
    AgGrid(
        width='100%',
        :height='`${tableHeight}px`',
        :provider='tableProvider',
        :columnDefs='columnDefs',
        ref='sellerTable'
    )
        template(#right)
            .text-right {{ $t('Home-vue.last-30-day-data') }}
    v-snackbars(
        :messages.sync='messages',
        color='primary',
        :timeout='-1',
        bottom,
        center
    )
        template(v-slot:action='{ close }')
            v-btn(text, @click='close()') {{ $t('Home-vue.snackbar.close') }}
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import AgGrid from '@/components/agGrid.vue';
import { SellersHomeInfoBackend } from '@/api/home/sellersHomeInfo';
import { ColDef, ColGroupDef } from '@ag-grid-community/all-modules';
import AcosDiffCellRender from '../components/agGridRenders/AcosDiffCellRender.vue';
import PortfolioCellRender from '../components/agGridRenders/PortfolioCellRenders.vue';
import VSnackbars from 'v-snackbars';

@Component({
    components: { AgGrid, VSnackbars },
    name: 'Home',
})
export default class Home extends Vue {
    public tableProvider = SellersHomeInfoBackend;
    public messages: string[] = [];
    @Ref('sellerTable') public sellerTable!: AgGrid;
    public get columnDefs(): (ColDef | ColGroupDef)[] {
        return [
            {
                headerName: this.$tc('Home-vue.name'),
                field: 'name',
                type: 'text',
            },
            {
                headerName: this.$tc('Home-vue.sales30days-vs-60'),
                field: 'sales30days.totalSales',
                cellRendererParams: {
                    diff: 'sales60days.totalSales',
                },
                type: 'currencyDiff, rightAligned',
            },
            {
                headerName: this.$tc('Home-vue.campaignsales30days-vs-60'),
                field: 'campaigns30days',
                cellRendererParams: {
                    diff: 'campaigns60days',
                },
                type: 'currencyDiff, rightAligned',
            },
            {
                headerName: this.$tc('Home-vue.campaigncosts30days-vs-60'),
                field: 'campaignsCosts30days',
                cellRendererParams: {
                    diff: 'campaignsCosts60days',
                },
                type: 'currencyDiff, rightAligned',
            },
            {
                headerName: this.$tc('Home-vue.acos30days-vs-60'),
                cellRendererParams: {
                    sales30: 'campaigns30days.value',
                    sales60: 'campaigns60days.value',
                    costs30: 'campaignsCosts30days.value',
                    costs60: 'campaignsCosts60days.value',
                },
                cellRendererFramework: AcosDiffCellRender,
                type: 'rightAligned',
            },
            {
                headerName: this.$tc('Home-vue.portfolios'),
                field: 'portfolios',
                cellRendererFramework: PortfolioCellRender,
            },
        ];
    }
    public reload() {
        this.sellerTable.reloadTable();
    }

    public addMessages(message: string) {
        this.messages.push(message);
    }

    public tableHeight = 400;
    changeTableHeight() {
        this.tableHeight = window.innerHeight - 60 ?? 400;
    }
    public async mounted() {
        this.changeTableHeight();
        window.onresize = this.changeTableHeight;
    }
}
</script>


