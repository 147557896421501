<template lang="pug">
.d-flex
    v-dialog(
        v-if='!refreshing',
        v-model='dialog',
        persistent,
        max-width='600px',
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on }')
            v-btn.ma-auto(color='primary', dark, v-on='on', small, icon)
                v-icon mdi-refresh
        v-card(:loading='loading')
            v-card-title
                span.headline {{ $tc('RefreshAmazonData-vue.title') }}
            v-card-text
                p {{ $tc('RefreshAmazonData-vue.body') }}
            v-card-actions
                v-spacer
                v-btn(:disabled='loading', text, @click='dialog = false') {{ $tc('RefreshAmazonData-vue.no') }}
                v-btn(:disabled='loading', text, @click='refreshSeller') {{ $tc('RefreshAmazonData-vue.yes') }}
    v-tooltip(v-else, top)
        template(v-slot:activator='{ on, attrs }')
            v-progress-circular.ma-auto.pl-7(
                indeterminate,
                color='primary',
                v-on='on',
                :size='15',
                :width='2'
            )
        span {{ $tc('RefreshAmazonData-vue.refreshing') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';

@Component<RefreshAmazonData>({
    name: 'RefreshAmazonData',
})
export default class RefreshAmazonData
    extends Vue
    implements ICellRendererComp
{
    public getGui!: any;
    public dialog = false;
    public params!: ICellRendererParams & {
        refresh: (_id: string) => Promise<void>;
    };
    public loading = false;
    public refreshing = false;

    public created() {
        this.refreshing =
            typeof this.params !== undefined &&
            this.params.data &&
            this.params.data.allReportsDone !== 'undefined'
                ? !this.params.data.allReportsDone
                : true;
    }

    public async refreshSeller() {
        this.loading = true;
        await this.params.refresh(this.params.data).catch((err) => {
            // eslint-disable-next-line
            console.error(err);
        });
        this.loading = false;
        this.dialog = false;
    }

    public refresh(
        params: ICellRendererParams & {
            refresh: (_id: string) => Promise<void>;
        },
    ) {
        this.params = params;
        return true;
    }
}
</script>

<style lang="scss" scoped></style>


