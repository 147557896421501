<template lang="pug">
v-container.fill-height(fluid)
    v-row(dense)
        v-col(cols='6')
            //- v-btn(dark, color='teal', @click='reloadTable')
                v-icon(left) mdi-reload
                | {{ $t('agGrid-vue.reload-table') }}
            slot(name='left')
        v-col(cols='6')
            slot(name='right')
    v-row(dense)
        v-col(cols='12')
            ag-grid-vue.ag-theme-material(
                :style='`width: ${width}; height: ${height};`',
                :gridOptions='gridOptions',
                :modules='modules'
            )
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AgGridVue } from '@ag-grid-community/vue';
import {
    Module,
    ColDef,
    ColGroupDef,
    GridOptions,
    GridApi,
    ColumnApi,
    GridReadyEvent,
    IDatasource,
    AllCommunityModules,
} from '@ag-grid-community/all-modules';

import BooleanCellRender from './agGridRenders/BooleanCellRender.vue';
import BooleanFilter from './agGridFilters/BooleanFilter.vue';

import { API } from '@/api/API';
import BooleanEditor from './agGridEditors/BooleanEditor.vue';
import TextEditor from './agGridEditors/TextEditor.vue';
import CurrencyCellRender from './agGridRenders/CurrencyCellRender.vue';
import CurrencyDiffCellRender from './agGridRenders/CurrencyDiffCellRender.vue';

@Component<AgGrid>({
    name: 'AgGrid',
    components: {
        AgGridVue,
    },
})
export default class AgGrid extends Vue {
    @Prop({ default: '500px' }) width!: string;
    @Prop({ default: '500px' }) height!: string;
    @Prop({ required: true }) columnDefs!: Array<ColDef | ColGroupDef>;
    @Prop() rowData!: any[];
    @Prop() provider!: typeof API;
    @Prop() frameworkComponents!: GridOptions['frameworkComponents'];
    @Watch('columnDefs') updateColumnDefs() {
        if (this.gridOptions.api) {
            this.gridOptions.api.setColumnDefs(this.columnDefs);
        }
    }
    public modules: Module[] = AllCommunityModules;
    public gridOptions: GridOptions = {
        defaultColDef: {
            resizable: true,
        },
        onGridReady: this.onGridReady,
        rowData: this.rowData,
        enableCellTextSelection: true,
        rowModelType: !this.rowData ? 'infinite' : undefined,
        infiniteInitialRowCount: 1,
        stopEditingWhenCellsLoseFocus: true,
        cacheBlockSize: 200,
        columnTypes: {
            text: {
                filter: 'agTextColumnFilter',
                filterParams: {
                    resetButton: true,
                    applyButton: true,
                    debounceMs: 200,
                },
                cellEditorFramework: TextEditor,
            },
            boolean: {
                cellRendererFramework: BooleanCellRender,
                filterFramework: BooleanFilter,
                cellEditorFramework: BooleanEditor,
            },
            currency: {
                cellRendererFramework: CurrencyCellRender,
            },
            currencyDiff: {
                cellRendererFramework: CurrencyDiffCellRender,
                type: 'rightAligned',
            },
        },
        frameworkComponents: this.frameworkComponents,
    };
    public gridApi: GridApi | null = null;
    public columnApi: ColumnApi | null = null;

    public beforeMount() {
        this.gridOptions.columnDefs = this.columnDefs;
    }

    public onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.setDatasource(this.dataSource);
        this.gridApi.sizeColumnsToFit();
    }

    public get dataSource(): IDatasource {
        // const provider = this.provider;
        // const gridApi = this.gridApi;
        const datasource: IDatasource = {
            getRows: (p) => new this.provider().table(p, this.gridApi),
        };
        return datasource;
    }

    public reloadTable() {
        if (this.gridOptions && this.gridOptions.api) {
            this.gridOptions.api.refreshInfiniteCache();
            this.gridOptions.api.setRowCount(
                this.gridOptions.api.getInfiniteRowCount() || 0,
                false,
            );
            this.gridOptions.api.ensureIndexVisible(1);
            this.gridOptions.api.getSelectedNodes().forEach((r) => {
                r.setSelected(false);
            });
        }
    }
}
</script>

<style lang="scss">
@import '~@ag-grid-community/all-modules/dist/styles/ag-grid';
@import '~@ag-grid-community/all-modules/dist/styles/ag-theme-material.min';
</style>


