<template lang="pug">
v-card
    v-text-field.mx-2(
        :label='$t("AssignedSellersEditor-vue.search")',
        append-icon='mdi-magnify',
        v-model='search',
        ref='search'
    )
    v-list(v-if='params && params.value', dense, max-height='300')
        v-list-item(v-for='(seller, i) in getSellers', :key='i')
            v-list-item-action
                v-checkbox(v-model='seller.assigned')
            v-list-item-content {{ seller.name }}
    v-card-text(v-else) {{ $t('AssignedSellersEditor-vue.no-sellers') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellEditorParams,
    ICellEditorComp,
} from '@ag-grid-community/all-modules';
import { SellersBackend } from '@/api/sellers/sellers';

type seller = { _id: string; name: string; assigned?: boolean };

@Component<AsignedSellersEditor>({ name: 'AsignedSellersEditor' })
export default class AsignedSellersEditor
    extends Vue
    implements ICellEditorComp
{
    public params!: ICellEditorParams;
    public getGui!: any;
    public sellers: seller[] = [];

    public async created() {
        const allSellers = new SellersBackend().getClientSellers({
            getSellersForClient: this.params.data.permissions.some(
                (p: string) => p === 'agency-client',
            ),
        });
        this.sellers = (this.params.value || []).map((s: seller) => {
            s.assigned = true;
            return s;
        });
        const notAssigned = (await allSellers)
            .filter(
                (as: seller) =>
                    !this.sellers.find((s: seller) => s._id === as._id),
            )
            .map((as: seller) => {
                as.assigned = false;
                return as;
            });
        this.sellers.push(...notAssigned);
    }

    public refresh(params: ICellEditorParams) {
        this.params = params;
        return true;
    }
    public getValue() {
        return this.sellers.filter((s) => s.assigned);
    }
    public isPopup() {
        return true;
    }

    public get getSellers() {
        return this.sellers.filter((a) => a.name.indexOf(this.search) >= 0);
    }

    public search = '';
}
</script>

<style lang="scss" scoped>
.v-list {
    overflow-y: auto;
}
</style>


