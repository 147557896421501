<template lang="pug">
v-card
    v-text-field.mx-2(
        :label='$t("AssignedCountriesEditor-vue.search")',
        append-icon='mdi-magnify',
        v-model='search',
        ref='search'
    )
    v-list(dense, max-height='300')
        v-list-item(v-for='(country, i) in getCountries', :key='i')
            v-list-item-action
                v-checkbox(v-model='country.assigned')
            v-list-item-content {{ $t('countries.names.' + country.name) }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellEditorParams,
    ICellEditorComp,
} from '@ag-grid-community/all-modules';
import { CountriesBackend } from '@/api/countries/countries';
import { components } from '@/api/models/Epinium';

type assignedCountry = { _id: string; name: string; assigned?: boolean };

@Component<AssignedCountriesEditor>({ name: 'AssignedCountriesEditor' })
export default class AssignedCountriesEditor
    extends Vue
    implements ICellEditorComp
{
    public params!: ICellEditorParams & {
        countries: components['schemas']['CountryDTO'][];
    };
    public getGui!: any;
    public countries: assignedCountry[] = [];

    public async created() {
        const allCountries = new CountriesBackend().getCountries();
        this.countries = (this.params.value || []).map((s: assignedCountry) => {
            s.assigned = true;
            return s;
        });
        // cogemos,los paises no asignados
        const notAssigned: assignedCountry[] = (await allCountries)
            .filter(
                (cy) =>
                    cy._id &&
                    cy.name &&
                    !this.countries.find((c) => c._id === (cy._id as any)),
            )
            .map((c) => ({
                assigned: false,
                _id: c._id || '',
                name: c.name || '',
            }));
        this.countries.push(...notAssigned);
    }

    public refresh(
        params: ICellEditorParams & {
            countries: components['schemas']['CountryDTO'][];
        },
    ) {
        this.params = params;
        return true;
    }
    public getValue() {
        return this.countries.filter((c) => c.assigned);
    }
    public isPopup() {
        return true;
    }

    public get getCountries() {
        return this.countries.filter((a) => a.name.indexOf(this.search) >= 0);
    }

    public search = '';
}
</script>

<style lang="scss" scoped>
.v-list {
    overflow-y: auto;
}
</style>


