<template lang="pug">
v-container.px-3
    v-radio-group(v-model='picked', dense)
        v-radio(:label='$t("BooleanFilter-vue.yes")', :value='true')
        v-radio(:label='$t("BooleanFilter-vue.no")', :value='false')
        v-radio(:label='$t("BooleanFilter-vue.all")', :value='null')
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IFilterComp, IFilterParams } from '@ag-grid-community/all-modules';

@Component<BooleanFilter>({
    watch: {
        picked(val, oldVal) {
            if (val !== oldVal) {
                this.params.filterChangedCallback();
            }
        },
    },
})
export default class BooleanFilter extends Vue implements IFilterComp {
    public params!: IFilterParams;
    public picked: boolean | null = null;
    public getGui!: any;

    public isFilterActive() {
        return this.picked !== null && this.picked !== undefined;
    }

    public getModel() {
        if (this.picked !== null && this.picked !== undefined) {
            return { filterType: 'boolean', filter: this.picked };
        }
    }

    public setModel(model: any) {
        this.picked = model ? model.value : null;
    }

    public doesFilterPass!: any;
}
</script>


