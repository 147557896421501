<template lang="pug">
v-card(min-width='300')
    v-container
        v-text-field(
            v-model='v$.value.$model',
            :label='headerField',
            required,
            :error-messages='v$.value.$errors.map((e) => e.$message)',
            ,
            :loading='v$.value.$pending'
        )
    v-card-actions
        v-spacer
        v-btn(color='blue darken-1', text='', @click='cancel') {{ $tc('TextEditor-vue.cancel') }}
        v-btn(
            color='blue darken-1',
            text='',
            @click='save',
            :disabled='v$.$invalid'
        ) {{ $tc('TextEditor-vue.save') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellEditorComp,
    ICellEditorParams,
} from '@ag-grid-community/all-modules';
import { required, minLength, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

@Component<TextEditor>({
    name: 'TextEditor',
    setup() {
        return { v$: useVuelidate({}) };
    },
    validations() {
        return {
            value: {
                required: helpers.withMessage(
                    this.$tc('TextEditor-vue.error').toString(),
                    required,
                ),
                minLength: helpers.withMessage(
                    this.$tc('TextEditor-vue.error').toString(),
                    minLength(2),
                ),
            },
        };
    },
})
export default class TextEditor extends Vue implements ICellEditorComp {
    public params!: ICellEditorParams;
    public getGui!: any;
    public headerField!: string | undefined;
    public value = '';
    public initValue = '';
    public getValue() {
        return this.initValue;
    }
    public isPopup() {
        return true;
    }
    public created() {
        this.headerField = this.params.colDef.headerName;
        this.initValue = this.params.value;
        this.value = this.params.value;
    }
    public cancel() {
        this.value = this.initValue;
        this.params.stopEditing();
    }
    public async save() {
        this.v$.$touch();
        if (!this.v$.$invalid) {
            this.initValue = this.value;
            this.params.stopEditing();
        }
    }
}
</script>


<style lang="scss" scoped></style>
