<template lang="pug">
v-container.pa-0.mt-3(fluid)
    h2 {{ $t('Invoices-vue.invoices') }}
    ag-grid-vue.ag-theme-material(
        :style='`width: 100%; height: ${tableHeight}px;`',
        :gridOptions='gridOptions',
        :modules='modules'
    )
</template>

<script lang="ts">
import {
    AllCommunityModules,
    ColDef,
    ColGroupDef,
    ColumnApi,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IDatasource,
    Module,
} from '@ag-grid-community/all-modules';
import { Component, Vue } from 'vue-property-decorator';
import { ClientsBackend } from '@/api/clients/clients';
import { AgGridVue } from '@ag-grid-community/vue';
import CurrencyCellRender from '@/components/agGridRenders/CurrencyCellRender.vue';
import ChipCellRender from '@/components/agGridRenders/ChipRenderer.vue';
import dayjs from 'dayjs';
import DownloadButtonRenderer from '@/components/agGridRenders/DownloadButtonRenderer.vue';
@Component<Invoices>({
    name: 'Invoices',
    components: {
        AgGridVue,
    },
})
export default class Invoices extends Vue {
    public modules: Module[] = AllCommunityModules;
    public gridOptions: GridOptions = {
        defaultColDef: {
            resizable: true,
            sortable: false,
            filter: false,
        },
        onGridReady: this.onGridReady,
        enableCellTextSelection: true,
        rowModelType: 'infinite',
        infiniteInitialRowCount: 1,
        stopEditingWhenCellsLoseFocus: true,
        cacheBlockSize: 10,
    };
    public gridApi: GridApi | null = null;
    public columnApi: ColumnApi | null = null;
    public beforeMount() {
        this.gridOptions.columnDefs = this.columDefs;
    }

    public get columDefs(): (ColDef | ColGroupDef)[] {
        return [
            {
                field: 'created',
                headerName: this.$tc('Invoices-vue.payment_date'),
                valueFormatter: (p) =>
                    p.value
                        ? dayjs
                              .unix(p.value)
                              .format(this.$t('date-format-table').toString())
                        : '-',
            },
            {
                field: 'number',
                headerName: this.$tc('Invoices-vue.invoice-number'),
            },
            {
                field: 'subtotal',
                headerName: this.$tc('Invoices-vue.subtotal'),
                cellRendererFramework: CurrencyCellRender,
                valueGetter: (p) => {
                    if (p.data)
                        return {
                            value: p.data.subtotal / 100,
                            currency: 'EUR',
                        };
                    else return '-';
                },
            },
            {
                field: 'tax',
                headerName: this.$tc('Invoices-vue.tax'),
                cellRendererFramework: CurrencyCellRender,
                valueGetter: (p) => {
                    if (p.data)
                        return { value: p.data.tax / 100, currency: 'EUR' };
                    else return '-';
                },
            },
            {
                field: 'total',
                headerName: this.$tc('Invoices-vue.total'),
                cellRendererFramework: CurrencyCellRender,
                valueGetter: (p) => {
                    if (p.data)
                        return { value: p.data.total / 100, currency: 'EUR' };
                    else return '';
                },
            },
            {
                field: 'status',
                headerName: this.$tc('Invoices-vue.status'),
                valueGetter: (p) => {
                    if (p.data) {
                        return {
                            text: this.$t(p.data.status),
                            color:
                                p.data.status === 'paid'
                                    ? 'success'
                                    : p.data.status === 'uncollectible'
                                    ? 'error'
                                    : '',
                        };
                    }
                },
                cellRendererFramework: ChipCellRender,
            },
            {
                field: 'invoice_pdf',
                headerName: this.$tc('Invoices-vue.invoice_pdf'),
                cellRendererFramework: DownloadButtonRenderer,
            },
        ];
    }

    public onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.setDatasource(this.dataSource);
        this.gridApi.sizeColumnsToFit();
    }

    public last: string | null = null;
    public get dataSource(): IDatasource {
        const datasource: IDatasource = {
            getRows: (p) => {
                this.gridApi?.showLoadingOverlay();
                new ClientsBackend()
                    .invoices({
                        starting_after: this.last || undefined,
                    })
                    .then((response) => {
                        const invoices = response.data;
                        if (response.has_more)
                            this.last = invoices[invoices.length - 1].id;
                        p.successCallback(
                            invoices,
                            !response.has_more
                                ? p.startRow + invoices.length
                                : -1,
                        );
                        this.gridApi?.hideOverlay();
                    });
            },
        };
        return datasource;
    }

    public tableHeight = 400;
    changeTableHeight() {
        this.tableHeight = window.innerHeight - 320 ?? 400;
    }
    public async mounted() {
        this.changeTableHeight();
        window.onresize = this.changeTableHeight;
    }
}
</script>

<style lang="scss">
@import '~@ag-grid-community/all-modules/dist/styles/ag-grid';
@import '~@ag-grid-community/all-modules/dist/styles/ag-theme-material.min';
</style>

