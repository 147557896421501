<template lang="pug">
v-container(fluid)
    v-row(justify='center')
        v-dialog(
            v-model='dialog',
            persistent,
            max-width='600px',
            transition='dialog-bottom-transition'
        )
            template(v-slot:activator='{ on }')
                v-btn(color='error', icon, v-on='on', :disabled='disabled')
                    v-icon mdi-account-remove
            v-card
                v-card-title
                    span.headline {{ $tc('RemoveUser-vue.title') }}
                v-card-actions
                    v-spacer
                    v-btn(color='blue darken-1', text, @click='dialog = false') {{ $tc('RemoveUser-vue.no') }}
                    v-btn(color='blue darken-1', text, @click='removeUser') {{ $tc('RemoveUser-vue.yes') }}
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import {
    ICellRendererComp,
    ICellRendererParams,
} from '@ag-grid-community/all-modules';
import { UsersBackend } from '@/api/users/users';

@Component<RemoveUser>({
    name: 'RemoveUser',
})
export default class RemoveUser extends Vue implements ICellRendererComp {
    public getGui!: any;
    public dialog = false;
    public params!: ICellRendererParams & { delete: (_id: string) => void };
    public disabled = false;
    created() {
        new UsersBackend()
            .checkIfAnyOtherAdmin(this.params.data._id, this.params.data.client)
            .then((is) => {
                if (is) {
                    this.disabled = true;
                }
            });
    }

    public async removeUser() {
        await this.params.delete(this.params.value);
        this.dialog = false;
    }

    public refresh(
        params: ICellRendererParams & { delete: (_id: string) => void },
    ) {
        this.params = params;
        return true;
    }
}
</script>

<style lang="scss" scoped></style>


