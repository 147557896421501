<template lang="pug">
v-dialog(v-model='opened', :width='900', transition='dialog-bottom-transition')
    v-card
        v-card-title {{ $tc('VendorAdvertisingProfilesSelectorModal-vue.title') }}
        v-card-text
            v-row(v-if='checkedAdv')
                v-col
                    span(
                        v-if='advProfilesToSelect && advProfilesToSelect.length'
                    )
                        v-alert(color='blue', type='info') {{ $tc('VendorAdvertisingProfilesSelectorModal-vue.explanation') }}
                        v-col
                            v-select(
                                v-model='advertisingProfileSelected',
                                :items='getAdvertisingProfiles',
                                item-text='name',
                                item-value='value',
                                return-object
                            )
                    span(v-else)
                        v-alert(color='orange', type='warning') {{ $tc('VendorAdvertisingProfilesSelectorModal-vue.no-profiles-found') }}
            v-row(v-else)
                v-col.d-flex
                    v-progress-circular.mx-auto.my-5(
                        :size='50',
                        color='primary',
                        indeterminate
                    )
                v-col.text-subtitle-1.text-center(cols=12) {{ $tc('VendorAdvertisingProfilesSelectorModal-vue.waiting-msg') }}
        v-card-actions
            v-spacer
            v-btn(@click='opened = false') {{ $tc('VendorAdvertisingProfilesSelectorModal-vue.close') }}
            v-btn(
                color='success',
                v-if='checkedAdv && advProfilesToSelect && advProfilesToSelect.length',
                @click='saveAdvertisingProfile()',
                :disabled='!Object.keys(advertisingProfileSelected).length'
            ) {{ $tc('VendorAdvertisingProfilesSelectorModal-vue.assign') }}
</template>

<script lang="ts">
import { components } from '@/api/models/Epinium';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import SellersBackend from '@/api/sellers/sellers';

@Component<VendorAdvertisingProfilesSelectorModal>({
    name: 'VendorAdvertisingProfilesSelectorModal',
    watch: {},
})
export default class VendorAdvertisingProfilesSelectorModal extends Vue {
    @PropSync('modalIsOpen') opened!: boolean;
    @Prop({
        default: () => {
            return {};
        },
    })
    sellerID: components['schemas']['SellerDTO']['_id'];
    @Prop({
        default: () => {
            return [];
        },
    })
    advProfilesToSelect!: components['schemas']['GetAdvertisingProfilesResponseDTO']['profiles'];
    @Prop({ default: false }) checkedAdv!: boolean;
    public advertisingProfileSelected:
        | components['schemas']['ProfileResponseDTO']
        | any = {};

    get getAdvertisingProfiles() {
        this.advertisingProfileSelected = {};
        return this.advProfilesToSelect.map((advProfile) => {
            return {
                name: `${advProfile.name} - ${advProfile.id}`,
                value: advProfile.profileId,
                rawData: advProfile,
            };
        });
    }

    @Prop() advCredentialID!: any;

    public async saveAdvertisingProfile() {
        await SellersBackend.setAdvCredential({
            sellerID: this.sellerID as string,
            profileData: this.advertisingProfileSelected.rawData,
            advCredentialID: this.advCredentialID,
        });
        this.opened = false;
        // FUNCION GUARDADO
    }
}
</script>

<style></style>


